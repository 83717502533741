import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  downloadThroughAnchorLink,
  returnObject,
} from "../../../utils/helperUtil";
import moment from "moment";
import {
  DIV_NAME,
  EXEMPTED,
  AUDIT_AUTHORITY,
  MUN_NAME,
  NO,
  PAR_NAME,
  PROPERTY,
  RATE,
  RENT,
  STATUS_APPROVED,
  STATUS_ASSESSED,
  STATUS_DEACTIVATED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STR_NAME,
  VILL_NAME,
  YES,
} from "../../../constants/webapp";
import { configColor } from "../../../components/styled/Theme";
import { Visibility } from "../../../components/common/Visibility";
import useLocationData from "../../common/useLocationData";
import {
  CONFIGURABLES_API,
  INVOICE_API,
  RATE_PERCENTAGE_API,
  USAGE_API,
} from "../../../constants/api";
import useGenericGet from "../../common/useGenericGet";
import { DownloadLink } from "../../../components/common/Button";
import { Tooltip } from "antd";
import { CONST_LABEL_LOCAL_GOV } from "../../../constants/trading-license";
import {
  LABEL_DIVISION,
  LABEL_PARISH,
  LABEL_VILLAGE,
} from "../../../constants/property";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../../utils/statusUtils";
import { sTaxpayerName } from "../../../components/layouts/LeftMenu";
import { MakePaymentRevenueButton } from "../../trading-license/useTradingLicense";
import { useAppContext } from "../../../context/AppContext";

const useProperty = () => {
  const { contextData, updateContext } = useAppContext();

  const userRole = contextData?.role_name;

  const propertyTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        r_lat: item?.r_lat,
        r_lng: item?.r_lng,
        nin: item?.nin,
        owner_name: item?.owner_name,
        property_owner_name: item?.property_owner_name,
        ownership_type: item?.ownership_type_name,
        email: item?.email,
        mobile_number: item?.mobile_number,
        owner_address: item?.owner_address,
        building_name: item?.building_name,
        building_number: item?.building_number,
        remarks: item?.remarks,
        gross_value: Number(item?.gross_value || 0).toLocaleString(),
        ratable_value: Number(item?.ratable_value || 0).toLocaleString(),
        annual_rate: Number(item?.annual_rate || 0).toLocaleString(),
        purpose: item?.usage_name,
        purpose2: item?.sub_usage_name,
        // status: item?.status_details,
        valuation_roll_status: item?.valuation_roll_status,
        created_at: moment(item?.created_at).format("YYYY-MM-DD"),
        created_by: item?.created_by_name,
        assessments: (
          <Link
            to={`/assessments?tax_id=${item.id}&tax=property&has_arrears=${item.has_arrears_balance}&tax_api=${PROPERTY}`}
            style={{ color: configColor }}
          >
            Assessment
          </Link>
        ),
        prn: (
          <MakePaymentRevenueButton
            api={PROPERTY}
            id={item?.id}
            revenue_source={"property"}
          />
        ),

        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=property`,
                "certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${PROPERTY}/view/${item.id}`}
                // state={{ tax: "tax=property" }}
              >
                <FaEye />
              </Link>
              <Visibility
                show={
                  item?.status_details === "DEFERRED" &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${PROPERTY}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={
                  (item?.status_details === "APPROVED" ||
                    item?.status_details === "PAID") &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                {item?.active_status === 1 &&
                status === "assessed" &&
                item?.valuation_roll_status === true ? (
                  <>
                    <Tooltip title="Amendment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/amendment/property/${item.id}`}
                      >
                        <FaGem />
                      </Link>
                    </Tooltip>

                    <Tooltip title="Business Transfer">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`../business-transfers/request/property-taxes/${item.branch_code}`}
                      >
                        <FaAngleDoubleRight />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Reassessment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/reassessment/property/${item.id}`}
                      >
                        <FaMoneyBill />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Objection">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/objection/property/${item.id}`}
                      >
                        <PanTool sx={{ fontSize: 16 }} />
                      </Link>
                    </Tooltip>
                    <Visibility show={item?.outstanding_balance == 0}>
                      <Tooltip title="Deactivate">
                        <Link
                          style={{ padding: 5, paddingBottom: 0 }}
                          class=" mr-2"
                          to={`${PROPERTY}/business_deactivation/${item.id}`}
                        >
                          <FaBan />
                        </Link>
                      </Tooltip>
                    </Visibility>
                  </>
                ) : null}
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),
      ...returnObject(isFormData, {
        [sTaxpayerName]: `${item?.first_name} ${item?.surname}`,
      }),
      ...returnObject(!isFormData, {
        nin: item?.nin,
      }),
      ...returnObject(isFormData, {
        nin: item?.cin,
      }),
      brn: item?.brn,
      tin: item?.tin,
      email: item?.email,
      mobile_number: item?.mobile_number,

      [CONST_LABEL_LOCAL_GOV]: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      [LABEL_DIVISION]: isFormData ? item?.division_name : item?.division_name,
      [LABEL_PARISH]: isFormData ? item?.parish_name : item?.parish_name,
      [LABEL_VILLAGE]: isFormData ? item?.village_name : item?.village_name,
      street: isFormData ? item?.street_name : item?.street_name,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      plot_no: item?.r_plot_no,
      house_no: item?.r_house_no,
      remarks: item?.remarks,
      property_owner_name: item?.property_owner_name,
      usage: isFormData ? item?.purpose_name : item?.usage_name,
      payment_status: (item?.invoice?.payment_status || "")
        .replaceAll("_", " ")
        .toUpperCase(),
      sub_usage: isFormData ? item?.purpose2_name : item?.sub_usage_name,
      valuation_roll_status: item?.valuation_roll_status,
      prt_status: item?.r_prt_status ? "Yes" : "No",
      "General Comment": item?.remarks,
      ...returnObject(!isFormData, {
        "Gross Value": Number(item?.gross_value || 0).toLocaleString(),
        ratable_value: Number(item?.ratable_value || 0).toLocaleString(),
        rate_percentage: Number(
          item?.ratable_value_percentage || 0
        ).toLocaleString(),
      }),
      annual_rate: Number(item?.annual_rate || 0).toLocaleString(),
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        // status: item?.status_details,
      }),
      ...returnObject(!isFormData, {
        created_at: moment(item?.created_at).format("YYYY-MM-DD"),
        created_by:
          `${item?.created_by?.first_name} ${item?.created_by?.last_name}`
            ? `${item?.created_by?.first_name} ${item?.created_by?.last_name}`
            : "N/A",
      }),
    };
  };

  const propertyTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: MUN_NAME, header: "Municipality", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street", filter: {} },
    { id: "nin", header: "NIN", filter: {} },
    { id: "brn", header: "BRN", filter: {} },
    { id: "owner_name", header: "Registered Owner", filter: {} },
    { id: "property_owner_name", header: "Property Owner", filter: {} },
    { id: "ownership_type", header: "Ownership Type", filter: {} },
    { id: "mobile_number", header: "Mobile Number", filter: {} },
    { id: "email", header: "Email ", filter: {} },
    { id: "r_lat", header: "Latitude", filter: {} },
    { id: "r_lng", header: "Longitude", filter: {} },
    { id: "purpose", header: "Usage", filter: {} },
    { id: "purpose2", header: "Sub Usage", filter: {} },
    { id: "remarks", header: "Remarks", filter: {} },
    { id: "gross_value", header: "Gross Value", filter: {} },
    { id: "ratable_value", header: "Ratable Value", filter: {} },
    { id: "annual_rate", header: "Annual Value", filter: {} },
    {
      id: "valuation_roll_status",
      header: "Valuation Roll Status",
      filter: {},
    },
    { id: "created_at", header: "Created On", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },
      { id: "prn", header: "PRNs" },
      { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${PROPERTY}/${STATUS_ASSESSED}`;
        break;

      case STATUS_APPROVED:
        redirect = `${PROPERTY}/${STATUS_APPROVED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${PROPERTY}/${STATUS_REJECTED}`;
        break;

      default:
        redirect = `${PROPERTY}/${STATUS_PENDING}`;
        break;
    }

    return redirect;
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const amendableFields = [
    ...taxpayerAmdendables,
    {
      id: "prt_status",
      field: "r_prt_status",
      type: "select",
      options: "prtstatuses",
    },
    { id: "latitude", field: "r_lat", type: "number" },
    { id: "longitude", field: "r_lng", type: "number" },
    { id: "plot_no", field: "r_plot_no", type: "number" },
    { id: "house_no", field: "r_house_no", type: "number" },
    { id: "remarks", field: "remarks", type: "text" },
    {
      id: CONST_LABEL_LOCAL_GOV,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: LABEL_DIVISION,
      field: "division",
      type: "select",
      options: "divisions",
    },
    { id: LABEL_PARISH, field: "parish", type: "select", options: "parishes" },
    {
      id: LABEL_VILLAGE,
      field: "village",
      type: "select",
      options: "villages",
    },
    { id: "street", field: "street", type: "select", options: "streets" },
    {
      id: "valuation_roll_status",
      field: "valuation_roll_status",
      type: "select",
      options: "valuationstatuses",
    },
    {
      id: "property_owner_name",
      field: "property_owner_name",
      type: "text",
    },
  ];

  const objectionFields = [
    {
      id: "objection_type",
      field: "objection_type",
      type: "select",
      options: "valuationstatuses",
    },
    { id: "mobile_number", type: "mobile_number" },
    { id: "tin", type: "text" },
    { id: "owner_name", type: "text" },
    { id: "email", type: "text" },
    { id: "nin", type: "text" },
    {
      id: "valuation_roll_status",
      field: "valuation_roll_status",
      type: "select",
      options: "valuationstatuses",
    },
  ];

  const reassessFields = [
    {
      id: CONST_LABEL_LOCAL_GOV,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: LABEL_DIVISION,
      field: "division",
      type: "select",
      options: "divisions",
    },
    { id: LABEL_PARISH, field: "parish", type: "select", options: "parishes" },
    {
      id: LABEL_VILLAGE,
      field: "village",
      type: "select",
      options: "villages",
    },
    { id: "street", field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    { id: "usage", field: "purpose", type: "select", options: "usage" },
    { id: "sub_usage", field: "purpose2", type: "select", options: "subusage" },
    {
      id: "Rent",
      field: "r_prt_rent",
      type: "number",
    },
    {
      id: "Number of Units",
      field: "r_prt_units",
      type: "number",
    },
    {
      id: "Rate Per Square Meter",
      field: "rate_per_sq_meter",
      type: "number",
    },
    {
      id: "Total Built up area (Sqm)",
      field: "r_total_built_up_area_sqm",
      type: "number",
    },
    {
      id: "ratable_value",
      field: "ratable_value",
      type: "number",
    },
    {
      id: "Gross Value",
      field: "gross_value",
      type: "number",
    },
    {
      id: "rate_percentage",
      field: "rate_percentage",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "annual_rate",
      field: "annual_rate",
      type: "number",
      props: { readOnly: true },
    },
  ];

  return {
    propertyTableColumns,
    propertyTableData,
    viewData,
    getRedirect,
    getActions,
    amendableFields,
    reassessFields,
    objectionFields,
  };
};

export const usePropertyFormSelect = (
  form,
  updateFormValue,
  updateFormValues
) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: usage, loadData: getUsage } = useGenericGet();
  const { data: subusage, loadData: getSubUsage } = useGenericGet();
  const { data: rates, loadData: getRate } = useGenericGet();

  const valuationstatuses = [
    {
      id: YES,
      name: "Yes",
    },
    {
      id: NO,
      name: "No",
    },
  ];

  const prtstatuses = [
    {
      id: YES,
      name: "Yes",
    },
    {
      id: NO,
      name: "No",
    },
    {
      id: EXEMPTED,
      name: "Exempted",
    },
  ];

  const types = [
    {
      id: RENT,
      name: "Rent",
    },
    {
      id: RATE,
      name: "Rate",
    },
  ];

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) {
      getDivisions(form?.municipality);
    }
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getUsage({
      api: USAGE_API,
    });
  }, []);

  useEffect(() => {
    if (form?.purpose) {
      getSubUsage({
        api: CONFIGURABLES_API,
        params: { parent: form?.purpose },
      });

      if (form?.purpose && form?.municipality && form?.division) {
        getRate({
          api: RATE_PERCENTAGE_API,
          params: {
            property_usage: form?.purpose,
            municipality: form?.municipality,
            division: form?.division,
          },
        });
      }
    }
  }, [form?.municipality, form?.division, form?.purpose]);

  useEffect(() => {
    if (rates && form?.ratable_value) {
      const { rate_percentage, annual_rate } = (() => {
        const division_rate = rates?.annual_rate;
        const rate = division_rate
          ? division_rate
          : (rates?.results || [])
              .filter((item) => item.municipality === form?.municipality)
              .reduce((acc, curr) => curr.rate, 8);

        // Compute annual rate
        const annual_rate = Number(form?.ratable_value * rate) / 100;
        return { rate_percentage: rate, annual_rate };
      })();
      updateFormValues({ rate_percentage, annual_rate });
    }
  }, [rates, form?.ratable_value]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    usage: usage?.results || [],
    subusage: subusage?.results || [],
    valuationstatuses,
    prtstatuses,
    rates: rates || [],
    types: types || [],
  };
};

export default useProperty;

import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  RENT_AND_RATES,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_ASSESSED,
  BUSINESS_CATEGORIES,
  RENT_PAYMENT_INTERVALS,
  MUN_NAME,
  DIV_NAME,
  PAR_NAME,
  VILL_NAME,
  STR_NAME,
  STATUS_DEACTIVATED,
  AUDIT_AUTHORITY,
} from "../../constants/webapp";
import {
  displayDocuments,
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";
import { CONFIGURABLES_API, INVOICE_API } from "../../constants/api";
import { MakePaymentRevenueButton } from "../trading-license/useTradingLicense";
import { DownloadLink } from "../../components/common/Button";
import { Tooltip } from "antd";
import {
  sDivision,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
} from "../../components/layouts/LeftMenu";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { useAppContext } from "../../context/AppContext";

function useRentandRates() {
  const { contextData } = useAppContext();
  const userRole = contextData?.role_name;
  //Column names in the usage UI table
  const rentandRatesTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "mobile_number", header: "Mobile Number", filter: {} },
    { id: "owner_name", header: "Taxpayer Name", filter: {} },
    { id: "tin", header: "TIN", filter: {} },
    { id: "trade_title", header: "Business Name", filter: {} },
    { id: "email", header: "Email", filter: {} },
    { id: "nin", header: "NIN", filter: {} },
    { id: "brn", header: "BRN", filter: {} },
    { id: MUN_NAME, header: "Municipality", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish", filter: {} },
    { id: VILL_NAME, header: "Village/Cell", filter: {} },
    { id: STR_NAME, header: "Street", filter: {} },
    { id: "plot_no", header: "Plot Number", filter: {} },
    { id: "market_building_name", header: "Market/Building Name", filter: {} },
    {
      id: "business_tenant_name",
      header: "Business (Tenant Name)",
      filter: {},
    },
    { id: "business_category", header: "Business Category", filter: {} },
    { id: "unit_no", header: "Unit No", filter: {} },
    {
      id: "rent_payment_interval",
      header: "Rent Payment Interval",
      filter: {},
    },
    {
      id: "rent_payable",
      header: "Rent Payable (Per interval selected above)",
      filter: {},
    },
    {
      id: "monthly_rent",
      header: "Monthly Rent",
      filter: {},
    },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },
      { id: "prn", header: "PRNs" },
      { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  //Loading data into the rent and rates UI table
  const rentandRatesTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        mobile_number: item?.mobile_number,
        owner_name: item?.owner_name,
        tin: item?.tin,
        trade_title: item?.business_tenant_name,
        email: item?.email,
        nin: item?.nin,
        brn: item?.brn,
        nationality: item?.nationality,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        plot_no: item?.r_plot_no,
        r_lat: item?.r_lat,
        r_lng: item?.r_lng,
        market_building_name: item?.market_building_name,
        business_tenant_name: item?.business_tenant_name,
        business_category: item?.business_category_name,
        unit_no: item?.unit_no,
        rent_payment_interval: item?.rent_payment_interval_name,
        rent_payable: Number(item?.rent_payable).toLocaleString(),
        monthly_rent: Number(item?.monthly_rent).toLocaleString(),
        created_by: item?.created_by_name,
        assessments: (
          <Link
            to={`/assessments?tax_id=${item.id}&tax=rent_and_rates`}
            style={{ color: configColor }}
          >
            Assessment
          </Link>
        ),
        prn: (
          <MakePaymentRevenueButton
            api={RENT_AND_RATES}
            id={item?.id}
            revenue_source={"rent_and_rates"}
          />
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=rent_and_rates`,
                "certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        actions: (
          <>
            <Link
              class="mr-2"
              style={{ padding: 5, paddingBottom: 0 }}
              to={`/rent-and-rates/view/${item.id}`}
              // state={{ tax: "rent_and_rates" }}
            >
              <FaEye />
            </Link>

            <Visibility
              show={
                item?.status_details === "DEFERRED" &&
                userRole !== AUDIT_AUTHORITY
              }
            >
              <Link
                style={{ padding: 5, paddingBottom: 0 }}
                class=" mr-2"
                to={`/rent-and-rates/update/${item.id}`}
              >
                <FaEdit />
              </Link>
            </Visibility>
            <Visibility
              show={
                ["APPROVED", "PAID"].includes(item?.status_details) &&
                userRole !== AUDIT_AUTHORITY
              }
            >
              {status === "assessed" ? (
                <>
                  <Tooltip title="Amendment">
                    <Link
                      style={{ padding: 5, paddingBottom: 0 }}
                      class=" mr-2"
                      to={`/amendment/rent_and_rates/${item.id}`}
                    >
                      <FaGem />
                    </Link>
                  </Tooltip>
                  <Tooltip title="Reassessment">
                    <Link
                      style={{ padding: 5, paddingBottom: 0 }}
                      class=" mr-2"
                      to={`/reassessment/rent_and_rates/${item.id}`}
                    >
                      <FaMoneyBill />
                    </Link>
                  </Tooltip>

                  <Tooltip title="Business Transfer">
                    <Link
                      style={{ padding: 5, paddingBottom: 0 }}
                      class=" mr-2"
                      to={`../business-transfers/request/rent-and-rates/${item.branch_code}`}
                    >
                      <FaAngleDoubleRight />
                    </Link>
                  </Tooltip>

                  <Visibility show={item?.outstanding_balance == 0}>
                    <Tooltip title="Deactivate">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`${RENT_AND_RATES}/business_deactivation/${item.id}`}
                      >
                        <FaBan />
                      </Link>
                    </Tooltip>
                  </Visibility>
                  <Tooltip title="Objection">
                    <Link
                      style={{ padding: 5, paddingBottom: 0 }}
                      class=" mr-2"
                      to={`/objection/rent_and_rates/${item.id}`}
                    >
                      <PanTool sx={{ fontSize: 16 }} />
                    </Link>
                  </Tooltip>
                </>
              ) : null}
            </Visibility>
          </>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      mobile_number: item?.mobile_number,
      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),
      tin: item?.tin,
      brn: item?.brn,
      [item?.idTypeName]: item?.cin,
      business_name: item?.business_tenant_name,
      email: item?.email,
      [sMunicipality]: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      [sDivision]: isFormData ? item?.division_name : item?.division_name,
      [sParish]: isFormData ? item?.parish_name : item?.parish_name,
      [sVillage]: isFormData ? item?.village_name : item?.village_name,
      [sStreet]: isFormData ? item?.street_name : item?.street_name,
      plot_number: item?.r_plot_no,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      business_category: isFormData
        ? item?.category_name
        : item?.business_category_name,
      "Market/Building Name": item?.market_building_name,
      "Business (Tenant Name)": item?.business_tenant_name,
      unit_no: item?.unit_no,
      ...returnObject(!isFormData, {
        rent_payment_interval: item?.rent_payment_interval_name,
      }),
      "Rent Payable (Per interval selected above)": Number(
        item?.rent_payable
      ).toLocaleString(),
      monthly_rent: Number(item?.monthly_rent).toLocaleString(),
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        // Status: item?.status_details,
      }),
      ...returnObject(!isFormData, {
        "TENANT AGREEMENT": "",
        ...displayDocuments(item?.rent_and_rates_attachments),
      }),
      created_by: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    };
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${RENT_AND_RATES}/${STATUS_ASSESSED}`;
        break;

      case STATUS_APPROVED:
        redirect = `${RENT_AND_RATES}/${STATUS_APPROVED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${RENT_AND_RATES}/${STATUS_REJECTED}`;
        break;

      default:
        redirect = `${RENT_AND_RATES}/${STATUS_PENDING}`;
        break;
    }

    return redirect;
  };

  const amendableFields = [
    ...taxpayerAmdendables,

    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: sDivision,
      field: "division",
      type: "select",
      options: "divisions",
    },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    {
      id: sVillage,
      field: "village",
      type: "select",
      options: "villages",
    },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    {
      id: "business_category",
      field: "business_category",
      type: "select",
      options: "categories",
    },
    { id: "business_name", field: "business_tenant_name", type: "text" },
    {
      id: "Business (Tenant Name)",
      field: "business_tenant_name",
      type: "text",
    },
    { id: "Market/Building Name", field: "market_building_name", type: "text" },
    { id: "unit_no", field: "unit_no", type: "text" },
  ];

  const objectionFields = [
    // Objection on No Value
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "tin", field: "tin", type: "text" },
    { id: "brn", field: "brn", type: "text" },
    { id: sTaxpayerName, field: "owner_name", type: "text" },
    { id: "email", field: "email", type: "text" },

    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: sDivision,
      field: "division",
      type: "select",
      options: "divisions",
    },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    {
      id: sVillage,
      field: "village",
      type: "select",
      options: "villages",
    },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    {
      id: "business_category",
      field: "business_category",
      type: "select",
      options: "categories",
    },
    { id: "business_name", field: "business_tenant_name", type: "text" },
    {
      id: "Business (Tenant Name)",
      field: "business_tenant_name",
      type: "text",
    },
    { id: "Market/Building Name", field: "market_building_name", type: "text" },
    { id: "unit_no", field: "unit_no", type: "text" },

    // Objection on Value
    {
      id: "rent_payment_interval",
      field: "rent_payment_interval",
      type: "select",
      options: "rentPaymentInterval",
    },
    {
      id: "Rent Payable (Per interval selected above)",
      field: "rent_payable",
      type: "number",
    },
    {
      id: "monthly_rent",
      field: "monthly_rent",
      type: "number",
      props: { readOnly: true },
    },
  ];
  const reassessFields = [
    {
      id: "rent_payment_interval",
      field: "rent_payment_interval",
      type: "select",
      options: "rentPaymentInterval",
    },
    {
      id: "Rent Payable (Per interval selected above)",
      field: "rent_payable",
      type: "number",
    },
    {
      id: "monthly_rent",
      field: "monthly_rent",
      type: "number",
      props: { readOnly: true },
    },
  ];

  return {
    rentandRatesTableColumns,
    rentandRatesTableData,
    viewData,
    getRedirect,
    getActions,
    objectionFields,
    amendableFields,
    reassessFields,
  };
}

export const useRentAndRatesFormSelect = (form, updateFormValue) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: categories, loadData: getBusinessCategories } = useGenericGet();
  const { data: rentPaymentInterval, loadData: getRentPaymentInterval } =
    useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getBusinessCategories({
      api: CONFIGURABLES_API,
      params: { field: BUSINESS_CATEGORIES },
    });
    getRentPaymentInterval({
      api: CONFIGURABLES_API,
      params: { field: RENT_PAYMENT_INTERVALS },
    });
  }, []);

  useEffect(() => {
    if (form?.rent_payable && form?.rent_payment_interval) {
      const interval_name = (rentPaymentInterval?.results || [])
        .filter((item) => item.id === form?.rent_payment_interval)
        .reduce((acc, curr) => curr.name, "");
      if (interval_name == "Annually") {
        let amount = form?.rent_payable / 12;
        updateFormValue("monthly_rent", amount);
      } else if (interval_name == "Monthly") {
        let amount = form?.rent_payable;
        updateFormValue("monthly_rent", amount);
      } else if (interval_name == "Quarterly") {
        let amount = form?.rent_payable / 3;
        updateFormValue("monthly_rent", amount);
      }
    }
  }, [form?.rent_payable, form?.rent_payment_interval]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    categories: categories?.results || [],
    rentPaymentInterval: rentPaymentInterval?.results || [],
  };
};

export default useRentandRates;

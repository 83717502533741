export const BASE_API = process.env.REACT_APP_BASE_API;

export const BASE_URA_API = process.env.REACT_APP_URA_API;

export const BASE_DOC_API = process.env.REACT_APP_MEDIA_API;

export const BASE_OUTPUTS_API = "https://outputs.iras.go.ug/invoice/";

const getEndpointUrl = (path) => {
  return path;
};
// MASTERDATA MANAGEMENT
export const DISTRICTS_API = getEndpointUrl("configurables/all-locations/");
export const DIVISIONS_API = getEndpointUrl("configurables/all-locations/");
export const COUNTIES_API = getEndpointUrl("configurables/all-locations/");
export const SUB_COUNTIES_API = getEndpointUrl("configurables/all-locations/");
export const PARISHES_API = getEndpointUrl("configurables/all-locations/");
export const VILLAGES_API = getEndpointUrl("configurables/all-locations/");
export const STREETS_API = getEndpointUrl("configurables/all-locations/");
export const LOCATIONS_API = getEndpointUrl(
  "configurables/streets/add-location/"
);
export const UPDATE_STREET_API = getEndpointUrl("configurables/streets/");

// LG LOGOS AND SIGNATURES
export const DETAILED_LOCAL_GOVERNMENTS = getEndpointUrl(
  "configurables/detailed-local-governments/"
);

export const VOTE_CODE_LOCAL_GOVERNMENTS = getEndpointUrl(
  "configurables/streets/local-governments-without-vote-code/"
);

// UPLOAD LOGOS AND SIGNATURES
export const UPLOAD_LOGO_SIGNATURE = getEndpointUrl(
  "configurables/streets/upload-logo-signature/"
);

// LGS WITHOUT TAX HEAD
export const TAX_HEADS_STREET_API = getEndpointUrl(
  "configurables/streets/local-governments-without-tax-head/"
);

// UPDATE TAX HEAD
export const UPDATE_TAX_HEAD_API = getEndpointUrl(
  "configurables/streets/update-tax-head/"
);

export const API_DIVISIONS_API = getEndpointUrl("configurables/all-divisions/");
export const API_PARISH_API = getEndpointUrl("configurables/all-parishes/");
export const API_VILLAGE_API = getEndpointUrl("configurables/all-villages/");
export const API_STREET_API = getEndpointUrl("configurables/all-streets/");
//URA MASTERDATA
export const URA_DISTRICTS_API = getEndpointUrl("services/tin-data/districts/");
export const URA_COUNTIES_API = getEndpointUrl("services/tin-data/counties/");
export const URA_SUB_COUNTIES_API = getEndpointUrl(
  "services/tin-data/sub-counties/"
);
export const URA_PARISHES_API = getEndpointUrl("services/tin-data/parishes/");
export const URA_VILLAGES_API = getEndpointUrl("services/tin-data/villages/");
export const URA_BRN_VALIDATION_API = getEndpointUrl(
  "services/tin-data/validate-brn/"
);
export const URA_NIN_VALIDATION_API = getEndpointUrl(
  "services/tin-data/validate-nin/"
);
export const URA_INSTANT_CERTIFICATE_API = getEndpointUrl(
  "services/tin-data/tin-certificate/"
);
export const URA_ENTITY_TYPE_API = getEndpointUrl(
  "configurables/entity-types/"
);
export const URA_ENTITY_SUB_TYPE_API = getEndpointUrl(
  "configurables/entity-subtypes/"
);

export const MAIN_ACTIVITIES_API = getEndpointUrl(
  "services/tin-data/main-activities/"
);
export const MAIN_ACTIVITIES_DIVISION_API = getEndpointUrl(
  "services/tin-data/main-activities-divisions/"
);
export const MAIN_ACTIVITIES_CLASS_API = getEndpointUrl(
  "services/tin-data/main-activities-classess/"
);

//USER MANAGEMENT
export const USER_REGISTRATION_API = getEndpointUrl("accounts/register-user/");
export const USERS_API = getEndpointUrl("accounts/users/");
export const ALL_USERS_API = getEndpointUrl("accounts/all-users/");
export const CHANGE_PASSWORD_API = getEndpointUrl(
  "accounts/users/change-password/"
);
export const SET_PASSWORD_API = getEndpointUrl("accounts/users/set-password/");
export const ACTION_HISTORY_API = "audits/activity/";
export const ROLES_API = getEndpointUrl("accounts/all-roles/");
export const ROLE_API = getEndpointUrl("accounts/roles/");
export const PERMISSION_API = getEndpointUrl("permissions/");
export const DECTIVATE_USER_API = getEndpointUrl(
  "accounts/users/deactivate-user"
);

//PAYMENTS
export const BANK_CODE_API = getEndpointUrl("bank-code/");
export const PAY_MODE_API = getEndpointUrl("configurables/payment-modes/");
export const INVOICE_API = getEndpointUrl("billings/invoices/");
export const TRADING_LINCENSE_PRESUMPTIVE_INVOICE_API = getEndpointUrl('/billings/invoices/consolidated-trade-license-and-presumptive/')
export const PERIOD_API = getEndpointUrl("billings/periods/");
export const PRN_API = getEndpointUrl("billings/prns/");
export const PRN_RESEND_API = getEndpointUrl("billings/prns/resend-prn/");
export const PRN_CANCEL_API = getEndpointUrl("billings/prns/cancel/");
export const PRN_STATUS_API = getEndpointUrl("billings/prns/prn-status");
export const GENERATE_PAF_API = getEndpointUrl("billings/prns/generate-paf/");
export const GENERATE_PAF_API_V4 = getEndpointUrl("billings/prns/generate-paf-v4/");
export const PAYMENTS_API = getEndpointUrl("billings/payments/");
export const PAYMENTS_USER_API = getEndpointUrl("billings/my-payments");
export const LEDGER_API = getEndpointUrl("accountings/tax-payers-ledger/");
export const GENERAL_LEDGER_API = getEndpointUrl("accountings/general-ledger/");
export const REVENUE_LEDGER_API = getEndpointUrl("reports/cumulative-report/");
export const ARREARS_LEDGER_API = getEndpointUrl("accountings/arrears-ledger/");
export const REGISTRATION_CERTIFICATE_API = getEndpointUrl(
  "accountings/taxpayer-certificate/"
);

// VOTE CODES
export const API_VOTE_CODES = getEndpointUrl(
  "configurables/streets/update-vote-code/"
);

export const API_ARREARS_MANAGEMENT = getEndpointUrl("arrears/");

//MASTERDATA MANAGEMENT
export const LOCAL_GOVERNMENT_API = getEndpointUrl("local-governments/");

//MARKETS MANAGEMENTS
export const MARKETS_API = getEndpointUrl("/configurables/markets/");
// ALL MARKETS
export const ALL_MARKETS_API = getEndpointUrl("/configurables/all-markets/");
export const MARKET_STOCK_TYPES_API = getEndpointUrl(
  "configurables/facility-types/"
);
export const MARKET_DUES_RATES_API = getEndpointUrl(
  "/configurables/market-due-rates/"
);
export const MARKET_DUES_CATEGORIES_API = getEndpointUrl(
  "/configurables/market-due-categories/"
);
export const MARKET_DUES_ITEMS_API = getEndpointUrl(
  "/configurables/market-due-items/"
);

export const MARKET_FACILITY_TYPE_API = getEndpointUrl(
  "configurables/facility-types/"
);

export const MARKET_FACILITY_NUMBERS_API = getEndpointUrl(
  "configurables/facility-numbers/"
);

export const MARKET_FACILITIES_API = getEndpointUrl(
  "configurables/facilities/"
);

export const ALL_MARKET_FACILITIES_API = getEndpointUrl(
  "configurables/all-facilities/"
);

//CONFIGURATIONS

export const CONFIGURABLES_API = getEndpointUrl(
  "configurables/other-rs-dropdowns/"
);

export const API_ALL_CONFIGURABLES_ = getEndpointUrl(
  "configurables/all-other-rs-dropdowns/"
);

export const NATURE_OF_MARKET_API = getEndpointUrl(
  "configurables/nature-of-markets/"
);

export const ALL_NATURE_OF_MARKET_API = getEndpointUrl(
  "configurables/all-nature-of-markets/"
);

//PARK USER FEES MANAGEMENT
export const VEHICLE_OPERATION_CATEGORY_API = getEndpointUrl(
  "configurables/vehicle-operation-categories/"
);

export const PARKS_API = getEndpointUrl("configurables/parks/");

export const OBJECTIONS_API = getEndpointUrl("services/objections/");
export const VALUATION_OBJECTIONS_API = getEndpointUrl(
  "services/valuation-objections/"
);

export const VEHICLE_CLASSIFICATION_API = getEndpointUrl(
  "configurables/vehicle-classifications/"
);

export const VEHICLE_ASSESSMENT_API = getEndpointUrl(
  "configurables/vehicle-assessments/"
);

//REVENUE SOURCES MANAGEMENT
export const REVENUE_CATEGORY_API = getEndpointUrl(
  "configurables/revenue-categories/"
);

export const DEMAND_DATES_API = getEndpointUrl("configurables/demand-dates/");

export const CHARTS_API = getEndpointUrl("configurables/charts/");
export const FINANCIAL_YEARS_API = getEndpointUrl(
  "billings/periods/financial-years/"
);

//NATURE OF BUSINESS MANAGEMENT
export const NATURE_OF_BUSINESS_API = getEndpointUrl(
  "/configurables/trade-subcategories/"
);

export const NATURE_OF_BUSINESS_ALL_API = getEndpointUrl(
  "/configurables/all-trade-subcategories/"
);

export const BUSINESS_CATEGORIES_ALL_API = getEndpointUrl(
  "/configurables/all-trade-categories/"
);

//TENDERER REVENUE SOURCE SOURCE
export const TENDERER_REVENUE_SOURCE_ITEM_API = getEndpointUrl(
  "/configurables/tendered-revenue-source-items/"
);

export const API_ALL_TENDERER_REVENUE_SOURCE_ITEM = getEndpointUrl(
  "/configurables/all-tendered-revenue-source-items/"
);

export const DEACTIVATE_API_TENDERER = getEndpointUrl(
  "revenues/tenderers/deactivate-tenderer"
);

//LOCAL GOVERNMENTS MANAGEMENT
export const LOCAL_GOVERNMENTS_API = getEndpointUrl("local_governments/");
export const ALL_LOCAL_GOVERNMENTS_API = getEndpointUrl(
  "configurables/all-local-governments/"
);

//BUSINESS CATEGORIES MANAGEMENT
export const BUSINESS_CATEGORIES_API = getEndpointUrl(
  "/configurables/trade-categories/"
);

export const ALL_BUSINESS_CATEGORIES_API = getEndpointUrl(
  "/configurables/all-trade-categories/"
);

export const BUSINESS_ALL_CATEGORIES_API = getEndpointUrl(
  "/configurables/trade-categories/"
);

//TRADING LICENSE REGISTRATION
export const TRADING_LICENSE_API = getEndpointUrl("revenues/trade-licenses/");

//TRADING LICENSE AMENDMENT
export const TRADING_LICENSE_AMENDMENT_API = getEndpointUrl(
  "amendments/trade-license-amendments/"
);

//TRADING LICENSE AMENDMENT
export const RENT_AND_RATES_AMENDMENT_API = getEndpointUrl(
  "amendments/rent-and-rates-amendments/"
);

//PROPERTY VALUATION REGISTRATION
export const PROPERTY_VALUATION_API = getEndpointUrl(
  "revenues/property-valuations/"
);

//LOCAL SERVICE TAX REGISTRATION
export const LOCAL_SERVICE_TAX_API = getEndpointUrl("revenues/lsts/");

//LOCAL SERVICE TAX AMENDMENTS
export const LOCAL_SERVICE_TAX_AMENDMENT_API = getEndpointUrl(
  "amendments/lst-amendments/"
);

// GROUND REGISTRATION
export const GROUND_RENT_API = getEndpointUrl("revenues/ground-rents/");

// TENDERER REGISTRATION
export const TENDERER_API = getEndpointUrl("revenues/tenderers/");

// OUTDOOR ADVERTISING REGISTRATION
export const OUTDOOR_ADVERTISING_API = getEndpointUrl(
  "revenues/outdoor-advertisings/"
);

//PROPERTY TAX REGISTRATION
export const PROPERTY_TAX_API = getEndpointUrl("revenues/property-taxes/");

//HOTEL FACILITY RATING
export const HOTEL_FACILITY_RATING_API = getEndpointUrl(
  "configurables/hotel-facility-ratings/"
);

//ASSESSMENT FINANCIALS YEARS
export const ASSESSMENT_FINANCIAL_YEARS_API = getEndpointUrl(
  "billings/periods/financial-years"
);

export const LEDGER_STATEMENT_API = getEndpointUrl("accountings/statement/");

export const BUSINESS_DEACTIVATION_API = getEndpointUrl(
  "business-deactivations/business-deactivations/"
);

export const BUSINESS_REACTIVATION_API = getEndpointUrl(
  "business-deactivations/business-deactivations/{id}/reactivate-business/"
);

//GRIEVANCES
export const GRIEVANCES_API = getEndpointUrl("grievances/grievances/");

//EXTERNAL GRIEVANCES
export const GRIEVANCES_EXTERNAL_API = getEndpointUrl(
  "grievances/create-grievances/"
);

//REVENUE BUDGETING
export const ECONOMIC_GROWTH_API = getEndpointUrl("budgeting/economic-growth/");

export const FORECAST_CONFIG_API = getEndpointUrl(
  "budgeting/forecast-configs/"
);

export const REVENUE_SOURCE_API = getEndpointUrl("budgeting/revenue-sources/");

export const REVENUE_SHARE_RATIO_API = getEndpointUrl(
  "budgeting/revenue-share-configs/"
);

export const SCHEDULE_API = getEndpointUrl("billings/schedules/");

export const SCHEDULE_ALL_API = getEndpointUrl("billings/all-schedules/");

//GRIEVANCES QUERY ASSSIGNMENTS
export const GRIEVANCES_QUERY_ASSIGNMENTS_API = getEndpointUrl(
  "grievances/query-assignments/"
);

// GRIEVANCES STATUS
export const GRIEVANCES_STATUS_API = getEndpointUrl(
  "grievances/grievances/grievance-status/"
);

// GRIEVANCES ASSIGNMENTS SUMMARY
export const GRIEVANCES_ASSIGNMENTS_SUMMARY_API = getEndpointUrl(
  "grievances/query-assignments-summary/"
);

// GRIEVANCES SUMMARY
export const GRIEVANCES_SUMMARY_API = getEndpointUrl(
  "grievances/grievances-summary/"
);

//GRIEVANCES CATEGORIES.
export const GRIEVANCES_CATEGORIES_API = getEndpointUrl(
  "grievances/grievance-categories/"
);

//GRIEVANCES PRIORITY LEVELS.
export const GRIEVIENCES_PRIORITY_API = getEndpointUrl(
  "grievances/grievance-priority-levels/"
);

//HOTEL CLASSIFICATIONS
export const HOTEL_CLASSIFCATIONS_API = getEndpointUrl(
  "configurables/lht-classifications/"
);

//LOCAL HOTEL TAX REGISTRATION
export const LOCAL_HOTEL_TAX_API = getEndpointUrl("revenues/lhts/");

//LOCAL HOTEL TAX AMENDMENT
export const LOCAL_HOTEL_TAX_AMENDMENT_API = getEndpointUrl(
  "amendments/lht-amendments/"
);

//OPERARATIONAL PERMIT REGISTRATION
export const OPERRATIONAL_PERMIT_API = getEndpointUrl(
  "revenues/operational-permits/"
);

//OPERARATIONAL PERMIT AMENDMENT
export const OPERRATIONAL_PERMIT_AMENDMENT_API = getEndpointUrl(
  "amendments/operational-permit-amendments/"
);

//MARKET RENTS REGISTRATION
export const MARKET_RENT_API = getEndpointUrl("revenues/market-rents/");

//MARKET RENTS REGISTRATION
export const MARKET_RENT_AMENDMENT_API = getEndpointUrl(
  "amendments/market-rent-amendments/"
);

//COMMERCIAL ROAD USER REGISTRATION
export const COMMERCIAL_ROAD_USER_API = getEndpointUrl(
  "revenues/commercial-vehicles/"
);

// export const URA_PARK_USER_FEES_API = getEndpointUrl(
//   "service/vehicle/"
// );

export const URA_CLASSIFIERS_DETAILS_API = `${BASE_URA_API}ura/instant-tin/classifiers/`;

export const DOCUMENTS_OUTPUTS_API = `${BASE_OUTPUTS_API}`;

export const URA_VEHICLE_DETAILS_API = getEndpointUrl("service/vehicle/");

//ADVANCED_VEHICLE_INCOME_TAX_API
export const API_ADVANCE_INCOME = getEndpointUrl(
  "revenues/advance-income/"
);

// URA INSTANT TIN
export const URA_INSTANT_TIN_API = getEndpointUrl("instant-tin/");
export const INDIVIDUAL_INSTANT_TIN_API = getEndpointUrl(
  "services/tin-data/individual-tin/"
);
export const NON_INDIVIDUAL_INSTANT_TIN_API = getEndpointUrl(
  "services/tin-data/non-individual-tin/"
);

//MARKET DUES REGISTRATION
export const MARKET_DUES_API = getEndpointUrl("revenues/market-dues/");

// TIN REGISTER
export const TIN_API = getEndpointUrl("services/tin-data/");

// LG PROFILE
export const BLOGS_CONTENTS_API = getEndpointUrl("blogs/contents/");

export const BLOGS_CONTENTS_EXTERNAL_API = getEndpointUrl(
  "blogs/contents-list/"
);

export const BLOGS_PROFILE_TYPES_API = getEndpointUrl("blogs/profile-types/");

//HOTEL CLASSIFICATION
export const HOTEL_CLASSIFICATION_API = getEndpointUrl(
  "configurables/lht-classifications/"
);

//HOTEL RATING
export const HOTEL_RATE_API = getEndpointUrl(
  "configurables/hotel-facility-ratings/"
);

//LHT RATES
export const LHT_RATES_API = getEndpointUrl("/configurables/lht-rates/");

//LST RATES
export const LST_RATES_API = getEndpointUrl("/configurables/lst-rates/");

//ROOMS
export const ROOMS_API = getEndpointUrl("/configurables/lht-rooms/");

//PLANNING RATES
export const PLANNING_RATES_API = getEndpointUrl(
  "/development-control/planning-rates/"
);

//PLANNING RATES
export const FETCH_PLANNING_RATES_API = getEndpointUrl(
  "/development-control/planning-rates/permit-rates/"
);

//PERMIT FEE TYPES
export const PERMIT_FEES_API = getEndpointUrl(
  "/development-control/permit-fees-types/"
);

//ALL PERMIT FEE TYPES
export const ALL_PERMIT_FEES_API = getEndpointUrl(
  "/development-control/all-permit-fees-types/"
);

//PERMIT FEE
export const FEES_API = getEndpointUrl("/development-control/permit-fees/");

//ALL PERMIT FEE
export const ALL_FEES_API = getEndpointUrl(
  "/development-control/all-permit-fees/"
);

//LAND USE
export const LAND_USE_API = getEndpointUrl("/configurables/land-uses/");
export const SUB_LAND_USE_API = getEndpointUrl("sub-land-uses/");

//SCRUTINY WORKFLOWS
export const SCRUTINY_WORKFLOW_API = getEndpointUrl("scrutiny/workflows/");
export const ALL_SCRUTINY_WORKFLOW_API = getEndpointUrl(
  "scrutiny/all-workflows/"
);

//SCRUTINY WORKFLOWS CONFIG
export const SCRUTINY_WORKFLOW_CONFIG_API = getEndpointUrl(
  "/scrutiny/workflow-configs/"
);

//SUPPORT USER GROUPS
export const SUPPORT_USER_GROUPS_API = getEndpointUrl(
  "/grievances/support-user-groups/"
);

//DEVELOPMENT CONTROL ROLES
export const DEVELOPMENT_CONTROL_ROLES_API = getEndpointUrl(
  "/scrutiny/development-control-workflow-configs/"
);

//STAGES
export const STAGES_API = getEndpointUrl("/configurables/stages/");

//MUNICIPALITIES
export const MUNICIPALITIES_API = getEndpointUrl(
  "/comfigurables/municipalities/"
);

//DEMAND NOTICE
export const MOBILE_CONTACTS_CONFIGURE_API = getEndpointUrl(
  "/configurables/streets/configure-mobile-contacts/"
);

// WALLS
export const WALLS_API = getEndpointUrl("walls/");

export const FINISH_WALLS_API = getEndpointUrl("finish-wall/");

//BUILDING TYPES

export const BUILDING_TYPES_API = getEndpointUrl("building-types/");

// FLOOR FINISH
export const FLOOR_FINISH_API = getEndpointUrl("floor-finish/");

// ROOF COVERING
export const ROOF_COVERING_API = getEndpointUrl("roof-covering/");

// CEILING
export const CEILING_API = getEndpointUrl("ceiling/");

// SECURITY_MEASURES
export const SECURITY_MEASURES_API = getEndpointUrl("security-measures/");

//DEPARTMENTS API
export const DEPARTMENTS_API = getEndpointUrl("departments/");

//BOUNDARY FENCE
export const BOUNDARY_FENCE_API = getEndpointUrl("boundary-fence/");

//ACCOMMODATION CATEGORY
export const ACCOMMODATION_CATEGORY_API = getEndpointUrl(
  "accommodation-category/"
);

//DEVELOPMENT CONTROL

//DEVELOPMENT PERMIT
export const DEVELOPMENT_PERMIT_API = getEndpointUrl(
  "revenues/development-permits/"
);

//DEVELOPMENT PERMIT STATUS
export const DEVELOPMENT_PERMIT_STATUS_API = getEndpointUrl(
  "development-control/inspection-officers/permit-application-status/"
);

/** Markets relates apis */
export const API_MARKET_DUE_CATEGORIES = getEndpointUrl(
  "configurables/market-due-categories/"
);
//SUBDIVISION_CONSOLIDATION PERMIT
export const SUBDIVISION_CONSOLIDATION_PERMIT_API = getEndpointUrl(
  "/revenues/subdivision-consolidation-permits/"
);

//RENOVATION PERMIT
export const RENOVATION_PERMIT_API = getEndpointUrl(
  "revenues/renovation-permits/"
);

//DEVELOPMENT PERMIT
export const BUILDING_PERMIT_API = getEndpointUrl(
  "/revenues/building-permits/"
);

export const LEASE_APPLICATION_API = getEndpointUrl(
  "revenues/lease-applications/"
);

// CONDOMINIUM PERMIT REGISTRATION
export const CONDOMINIUM_PERMIT_API = getEndpointUrl(
  "revenues/condominium-permits/"
);

//ADVERTISEMENT CLASSIFICATION API
export const ADVERTISEMENT_CLASSIFICATION_API = getEndpointUrl(
  "configurables/advertising-categories/"
);

export const OUTDOOR_ADVERTISING_RATES_API = getEndpointUrl(
  "configurables/outdoor-rates/"
);

//ROUTES API
export const ROUTES_API = getEndpointUrl("configurables/routes/");

//ROUTES API
export const TITLE_API = getEndpointUrl("services/tin-data/titles/");
export const GENDER_API = getEndpointUrl("services/tin-data/sex/");
export const MARITAL_STATUS_API = getEndpointUrl(
  "services/tin-data/marital-status/"
);
//STATUS API
export const STATUSES_API = getEndpointUrl("configurables/status/");

//ALL STATUS API
export const ALL_STATUSES_API = getEndpointUrl("configurables/all-status/");

//MARKET DUES FILE DOWNLOAD
export const MARKET_DUES_EXCEL_API = getEndpointUrl(
  "/revenues/market-dues/downloads/excel/"
);
export const MARKET_DUES_CSV_API = getEndpointUrl(
  "/revenues/market-dues/downloads/csv/"
);

//ADVANCED INCOME TAX FILE DOWNLOAD
export const VEHICLE_EXCEL_API = getEndpointUrl(
  "/revenues/advanced-income-taxes/downloads/excel/"
);
export const VEHICLE_CSV_API = getEndpointUrl(
  "/revenues/advanced-income-taxes/downloads/csv/"
);

//VEHICLE TAX RATES
export const VEHICLE_TAX_RATES_API = getEndpointUrl(
  "configurables/advance-income-tax-rates/"
);

export const VEHICLE_TAX_RATE_API = getEndpointUrl(
  "configurables/vehicle-tax-rates/"
);
// TIN FILE DOWNLOAD
export const TIN_EXCEL_API = getEndpointUrl("/instant-tin/downloads/excel/");
export const TIN_CSV_API = getEndpointUrl("/instant-tin/downloads/csv/");

//TRADE CATEGORY API
export const TRADE_CATEGORY_API = getEndpointUrl(
  "/configurables/trade-categories/"
);

//TRADE SUB CATEGORY API
export const TRADE_SUB_CATEGORY_API = getEndpointUrl(
  "/configurables/trade-subcategories/"
);

//LST CATEGORIES API
export const LST_CATEGORIES_API = getEndpointUrl(
  "/configurables/lst-categories/"
);

export const LST_NATURE_OF_BUSINESS_API = getEndpointUrl(
  "/configurables/nature-of-business/"
);

//CURRENCY
export const CURRENCY_API = getEndpointUrl("/configurables/currencies/");

//PRPOPERTY ANNUAL RATES
export const ANNUAL_RATES_API = getEndpointUrl(
  "/configurables/all-annual-rates/"
);

//PROPERTY ANNUAL RATES
export const YEARLY_RATES_API = getEndpointUrl("/configurables/annual-rates/");

//RATEABLE VALUE PERCENTAGES
export const RATEABLE_VALUE_PERCENTAGE_API = getEndpointUrl(
  "/configurables/rateable-value-percentages/"
);

//ESCALATION PERIODS
export const ESCALATION_PERIODS_API = getEndpointUrl(
  "/configurables/development-control-escalation-periods/"
);

//GROSS VALUE PERCENTAGES
export const GROSS_VALUE_PERCENTAGE_API = getEndpointUrl(
  "/configurables/gross-value-percentages/"
);

//VALUATION PERIODS
export const VALUATION_PERIODS_API = getEndpointUrl(
  "/configurables/valuation-periods/"
);

//LETTABLE AREA PERCENTAGES
export const LETTABLE_AREA_PERCENTAGES = getEndpointUrl(
  "/configurables/lettable-area-percentages/"
);

export const ANNUAL_RATES_APIS = getEndpointUrl("/configurables/annual-rates/");

// DASHBOARD API
export const DASHBOARD_SUMMARY_API = getEndpointUrl(
  "/reports/summary/?report_type=aggregated"
);

// REPORT SUMMARY FYS API
export const REPORT_SUMMARY_FYS_API = getEndpointUrl("/reports/summary-fys/");

// REVENUE FORECAST API
export const REVENUE_FORECAST_API = getEndpointUrl("reports/forecast/");

// DEVELOPMENT CONTROL API
export const DEVELOPMENT_CONTROL_API = getEndpointUrl(
  "/reports/development-control-summary/"
);

// BUDGET PERFORMANCE
export const BUDGET_PERFORMANCE_API = getEndpointUrl(
  "reports/budget-performance/"
);

//BANK CODES API
export const BANK_CODES_API = getEndpointUrl("/configurables/bank-codes/");
export const API_ALL_BANK_CODES = getEndpointUrl(
  "/configurables/all-bank-codes/"
);
export const ALL_BANK_CODES_API = getEndpointUrl(
  "/configurables/unauthenticated-bank-codes/"
);

//USAGE API

export const USAGES_API = getEndpointUrl("/configurables/other-rs-dropdowns/");

export const SUB_USAGE_API = getEndpointUrl(
  "/configurables/other-rs-dropdowns/"
);

export const USAGE_API = getEndpointUrl("/configurables/usages/");
export const SUB_USAGES_API = getEndpointUrl("/configurables/sub-usages/");

//REASON FOR OBJECTION API
export const REASON_FOR_OBJECTION_API = getEndpointUrl(
  "/configurables/reason-for-objection/"
);

//BUILDING CONDITION API
export const BUILDING_CONDITION_API = getEndpointUrl(
  "/configurables/building-condition/"
);

//RENT AND RATES API
export const RENT_AND_RATES_API = getEndpointUrl("/revenues/rent-and-rates/");

//RENT AND RATES API
export const PAYMENT_MODES_API = getEndpointUrl(
  "/configurables/payment-modes/"
);

//DEVELOPMENT_CONTROL_ACTORS
export const SERVICE_ACTORS_API = getEndpointUrl(
  "scrutiny/all-service-actors/"
);

export const DEVELOPMENT_CONTROL_ACTORS_API = getEndpointUrl(
  "scrutiny/all-development-control-actors/"
);

export const DEVELOPMENT_CONTROL_ACTORS_MULTIPLE_API = getEndpointUrl(
  "scrutiny/development-control-actors/multiple/"
);

export const DEVELOPMENT_CONTROL_ACTORS_MULTIPLE_WORKFLOWS_API = getEndpointUrl(
  "scrutiny/development-control-actors/multiple-workflow-actors/"
);

export const DEVELOPMENT_CONTROL_ACTORS_PERMISSIONS_API = getEndpointUrl(
  "/scrutiny/development-control-actors/permissions/"
);

//TITLE DESCRIPTION
export const TITLE_DESCRIPTION_API = getEndpointUrl(
  "/configurables/title-description/"
);
//TOOL TYPES API
export const TOOL_TYPES_API = getEndpointUrl("/configurables/tool-types/");

//TOOL SIZES API
export const TOOL_SIZES_API = getEndpointUrl("/configurables/tool-sizes/");

//SIDES API
export const SIDES_API = getEndpointUrl("/configurables/billboard-sides/");

//TURNOVER THRESHOLD
export const TURNOVER_THRESHOLD_API = getEndpointUrl(
  "/configurables/turnovers/"
);

//LST GRADES
export const LST_GRADES_API = getEndpointUrl("/configurables/all-lst-grades/");

//OPERATIONAL CATEGORIES
export const OPERATIONAL_CATEGORIES_API = getEndpointUrl(
  "/configurables/operational-permit-categories/"
);

export const OPERATIONAL_ALL_CATEGORIES_API = getEndpointUrl(
  "/configurables/all-operational-permit-categories/"
);

export const OPERATIONAL_SUB_CATEGORIES_API = getEndpointUrl(
  "/configurables/operational-permit-subcategories/"
);
export const OPERATIONAL_SUB_ALL_CATEGORIES_API = getEndpointUrl(
  "/configurables/all-operational-permit-subcategories/"
);

// SERVICE APPLICATION STATUS
export const SERVICE_APPLICATION_STATUS_API = getEndpointUrl(
  "/configurables/adhoc-report-modules/service-application-status/"
);

//TRADING LICENSE AMOUNT
export const TRADING_LICENSE_AMOUNT_API = getEndpointUrl(
  "/configurables/trading-license-amounts/"
);

//TRADING LICENSE AMOUNT
export const ALL_TRADING_LICENSE_AMOUNT_API = getEndpointUrl(
  "/configurables/all-trading-license-amounts/"
);

//PRESUMPTIVE TAX ASSESSMENT
export const PRESUMPTIVE_TAX_ASSESSMENT_API = getEndpointUrl(
  "/revenues/presumptive-tax/assess/"
);


//ONTR
export const ONTR_API = getEndpointUrl("/revenues/ontrs/");
export const EXTERNAL_ONTR_API = getEndpointUrl("/revenues/ontr-create/");

//REVENUE CATEGORIES
export const REVENUE_CATEGORIES_API = getEndpointUrl(
  "/configurables/revenue-categories/"
);

// All revenue categories
export const API_ALL_REVENUE_CATEGORIES = getEndpointUrl(
  "/configurables/all-revenue-categories/"
);

// All revenue categories
export const API_ALL_REVENUE_SOURCE_ITEMS = getEndpointUrl(
  "/configurables/all-revenue-source-items/"
);

export const EXTERNAL_REVENUE_CATEGORIES_API = getEndpointUrl(
  "/configurables/external-revenue-categories/"
);

//REVENUE SOURCE ITEMS
export const REVENUE_SOURCE_ITEMS_API = getEndpointUrl(
  "/configurables/revenue-source-items/"
);

export const EXTERNAL_REVENUE_SOURCE_ITEMS_API = getEndpointUrl(
  "/configurables/external-revenue-source-items/"
);

//REVENUE SOURCE STATUS
export const REVENUE_SOURCE_STATUS_API = getEndpointUrl(
  "configurables/adhoc-report-modules/revenue-source-application-status/"
);

//REVENUE BUDGET
export const REVENUE_BUDGET_API = getEndpointUrl("budgeting/budget-configs/");

//CHAINLINK PERMIT
export const CHAINLINK_PERMIT_API = getEndpointUrl(
  "/revenues/chainlink-permits/"
);

//HOARDING PERMIT
export const HOARDING_PERMIT_API = getEndpointUrl(
  "/revenues/hoarding-permits/"
);

//EXCAVATION PERMIT
export const EXCAVATION_PERMIT_API = getEndpointUrl(
  "/revenues/excavation-permits/"
);

//OUTDOOR ADVERTISEMENT PERMIT
export const OUTDOOR_ADVERTISEMENT_PERMIT_API = getEndpointUrl(
  "/revenues/outdoor-advertisement-permits/"
);

//OCCUPATIONAL PERMIT
export const OCCUPATIONAL_PERMIT_API = getEndpointUrl(
  "/revenues/occupation-permits/"
);

//DEMOLITION PERMIT
export const DEMOLITION_PERMIT_API = getEndpointUrl(
  "/revenues/demolition-permits/"
);

//ONWERSHIP TYPE
export const OWNERSHIP_TYPE_API = getEndpointUrl(
  "/configurables/ownership-types/"
);

//EXCEL/CV DOWNLOADS

//Adit Trial
export const AUDIT_TRAIL_CSV_API = `${ACTION_HISTORY_API}downloads/csv/`;
export const AUDIT_TRAIL_EXCEL_API = `${ACTION_HISTORY_API}downloads/excel/`;

//Trade Locense
export const TRADE_LICENSE_CSV_API = `${TRADING_LICENSE_API}downloads/csv/`;
export const TRADE_LICENSE_EXCEL_API = `${TRADING_LICENSE_API}downloads/excel/`;

//MARKET RENT
export const MARKET_RENT_CSV_API = `${MARKET_RENT_API}downloads/csv/`;
export const MARKET_RENT_EXCEL_API = `${MARKET_RENT_API}downloads/excel/`;

//PROPERTY TAXES
export const PROPERTY_TAX_CSV_API = `${PROPERTY_TAX_API}downloads/csv/`;
export const PROPERTY_TAX_EXCEL_API = `${PROPERTY_TAX_API}downloads/excel/`;

//PROPERTY TAXES
export const PROPERTY_VALUATION_CSV_API = `${PROPERTY_VALUATION_API}downloads/csv/`;
export const PROPERTY_VALUATION_EXCEL_API = `${PROPERTY_VALUATION_API}downloads/excel/`;

//COMMERCIAL ROAD USER
export const COMMERCIAL_ROAD_CSV_API = `${COMMERCIAL_ROAD_USER_API}downloads/csv/`;
export const COMMERCIAL_ROAD_EXCEL_API = `${COMMERCIAL_ROAD_USER_API}downloads/excel/`;

//OPERATIONAL PERMIT
export const OPERATIONAL_PERMIT_CSV_API = `${OPERRATIONAL_PERMIT_API}downloads/csv/`;
export const OPERATIONAL_PERMIT_EXCEL_API = `${OPERRATIONAL_PERMIT_API}downloads/excel/`;

//LOCAL SERVICE TAX
export const LOCAL_SERVICE_TAX_CSV_API = `${LOCAL_SERVICE_TAX_API}downloads/csv/`;
export const LOCAL_SERVICE_TAX_EXCEL_API = `${LOCAL_SERVICE_TAX_API}downloads/excel/`;

//GROUND RENT
export const GROUND_RENT_CSV_API = `${GROUND_RENT_API}downloads/csv/`;
export const GROUND_RENT_EXCEL_API = `${GROUND_RENT_API}downloads/excel/`;

//OUTDOOR ADVERTISING
export const OUTDOOR_ADVERTISING_CSV_API = `${OUTDOOR_ADVERTISING_API}downloads/csv/`;
export const OUTDOOR_ADVERTISING_EXCEL_API = `${OUTDOOR_ADVERTISING_API}downloads/excel/`;

//TENDERER EXPORT DOWNLOADS
export const TENDERER_CSV_API = `${TENDERER_API}downloads/csv/`;
export const TENDERER_EXCEL_API = `${TENDERER_API}downloads/excel/`;

//OBJECTIONS EXPORT DOWNLOADS
export const OBJECTIONS_CSV_API = `${OBJECTIONS_API}downloads/csv/`;
export const OBJECTIONS_EXCEL_API = `${OBJECTIONS_API}downloads/excel/`;

//PAYMENT EXPORT DOWNLOADS
export const PAYMENTS_CSV_API = `${PAYMENTS_API}downloads/csv/`;
export const PAYMENTS_EXCEL_API = `${PAYMENTS_API}downloads/excel/`;

//LEDGERS EXPORT DOWNLOADS
export const ARREARS_LEDGER_CSV_API = `${ARREARS_LEDGER_API}downloads/csv/`;
export const ARREARS_LEDGER_EXCEL_API = `${GENERAL_LEDGER_API}downloads/excel/`;
export const GENERAL_LEDGER_CSV_API = `${GENERAL_LEDGER_API}downloads/csv/`;
export const GENERAL_LEDGER_EXCEL_API = `${GENERAL_LEDGER_API}downloads/excel/`;
export const REVENUE_LEDGER_EXCEL_API = `reports/cumulative-report/excel`;
export const REVENUE_LEDGER_CSV_API = `reports/cumulative-report/csv`;

//PAYMENT EXPORT DOWNLOADS
export const PRNS_CSV_API = `${PRN_API}downloads/csv/`;
export const PRNS_EXCEL_API = `${PRN_API}downloads/excel/`;

//CONDOMINIUM EXPORT DOWNLOADS
export const CONDOMINIUM_PERMIT_CSV_API = `${CONDOMINIUM_PERMIT_API}downloads/csv/`;
export const CONDOMINIUM_PERMIT_EXCEL_API = `${TENDERER_API}downloads/excel/`;

export const API_GENERATE_BILL = "/api/billings/invoices/generate-bill/";
export const API_ADHOC_MODULE_CONFIGS = "/reports/modules/";
export const API_PERMIT_QUESTION = "/development-control/permit-questions/";
export const API_FILTERED_PERMIT_QUESTION =
  "/development-control/filtered-permit-questions/";
export const API_SITE_INSPECTION_QUESTION =
  "/development-control/site-inspection-questions/";
export const API_SITE_INSPECTION_QUESTIONS =
  "/development-control/all-site-inspection-questions/";
export const API_SITE_INSPECTION_ANSWER =
  "/development-control/site-inspection-configurable-answers/";

export const INSPECTION_OFFICERS_API =
  "/development-control/inspection-officers/";
export const API_FLEX_REPORT = "/reports/adhoc/";

// PUBLIC USER APIS
export const INVOICE_OWNER_API = "/billings/invoices-owner/";

// PERMIT SCHEDULE API
export const PERMIT_SCHEDULE_API = "/development-control/permit-schedules/";
export const PERMIT_MULTIPLE_SCHEDULE_API =
  "/development-control/permit-schedules/multiple/";
export const API_AMENDMENTS = "/services/ammendments/";

//BUSINESS TRANSFER API
export const API_BUSINESS_TRANSFER = "/services/business-transfers/";

// PERMIT SCHEDULE API
export const SEND_BACK_FOR_REVIEW_API =
  "/development-control/send-back-review/";

// PERMIT FEES
export const CONFIGURABLE_PERMIT_FEES_API =
  "/development-control/configurable-permit-fees/";

  export const NEW_CONFIGURABLE_PERMIT_FEES_API =
  "/development-control/configurable-permit-fees/rate/";


// CONFIG PERMIT FEES
export const CONFIG_PERMIT_FEES_API = "/development-control/permit-fees/";

export const API_REASSESSMENT = "/services/reassessment/";

// ROUTINE INSPECTION API
export const ROUTINE_INSPECTION_API =
  "/development-control/routine-inspections/";

// ENFORCEMENT API
export const ENFORCEMENT_API = "/development-control/enforcements/";

// PERMIT FEES CHARGES API
export const PERMIT_FEES_CHARGED_API =
  "/development-control/permit-fees-charged/multiple/";

//VALIDATE BRN API
export const VALIDATE_BRN_API = "/services/tin-data/validate-brn/";

//VALIDATE NIN API
export const VALIDATE_NIN_API = "/services/tin-data/validate-nin/";

//VALIDATE TIN API
export const VALIDATE_TIN_API = getEndpointUrl(
  "/services/tin-data/validate-tin/"
);

//SELF EMPLOYMENT RATES API
export const SELF_EMPLOYMENT_RATES_API =
  "/configurables/lst_self-employed-professionals/";

//DEVELOPMENT CONTROL PROFESSIONAL API
export const DEVELOPMENT_CONTROL_PROFESSIONAL_API =
  "/development-control/professionals/";

//TENDERER BILLING API
export const TENDERER_BILLING_API = "/revenues/tenderers/bill-tenderer/";

//LST EMPLOYEES
export const LST_EMPLOYEES_API = getEndpointUrl(
  "/billings/invoices/lst-employees/"
);

//LHT ROOMS
export const LHT_ROOMS_API = getEndpointUrl("/billings/invoices/lht-rooms/");

//LST REASSESSMENT
export const LST_REASSESSMENT_API = getEndpointUrl(
  "/services/lst-reassessments/"
);

//LHT REASSESSMENT
export const LHT_REASSESSMENT_API = getEndpointUrl(
  "/services/lht-reassessments/"
);

//NOTIFICATIONS API
export const NOTIFICATIONS_API = getEndpointUrl("/services/notifications/");

//NOTIFICATIONS API
export const READ_ALL_NOTIFICATIONS_API = getEndpointUrl(
  "/services/notifications/mark-all-as-read/"
);

//RATE PERCENTAGE
export const RATE_PERCENTAGE_API = getEndpointUrl(
  "/configurables/annual-rates/for-property/"
);

//SUBDIVISION RATE
export const SUBDVISION_RATE_API = getEndpointUrl(
  "/development-control/configurable-permit-fees/"
);

//OUTSTANDING BALANCE
export const OUTSTANDING_BALANCE_API = getEndpointUrl(
  "/billings/invoices/outstandings/"
);

//DOWNLOAD REPORT
export const DOWNLOAD_REPORT_API = getEndpointUrl(
  "/development-control/section-remarks/scrutiny-report/"
);
// NEWS SECTION
export const NEWS_API = getEndpointUrl("news/news/");

export const NEWS_LIST_EXTERNAL_API = getEndpointUrl("news/news-list/");

export const PROFESSIONALS_API = getEndpointUrl(
  "/development-control/professionals/"
);
